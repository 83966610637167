<template>
  <div>
      <Slider />
      <Services />
      <ProductPage />
      <WhyUs />
    
  </div>
</template>

<script>
export default {
    name: 'Home',
    components: {
       Slider: () => import('./slider'),
       WhyUs: () => import('./why-us'),
       Services: () => import('./services'),
       ProductPage: () => import('./products'),

    },

}
</script>

<style>

</style>